import { get, post, put, Delete } from "../axios";

// 产品分类-列表
export const infoProductClassifyList = (params) =>
  get("/web/info/productClassify/list", params);

// 产品分类-新增
export const infoProductClassifyAdd = (params) =>
  post("/web/info/productClassify/add", params);

// 产品分类-编辑
export const infoProductClassifyUpdate = (params) =>
  put("/web/info/productClassify/update", params);

// 产品分类-删除
export const infoProductClassifyDelete = (id) =>
  Delete("/web/info/productClassify/delete/" + id, "");
